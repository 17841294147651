import _defineProperty from '@babel/runtime/helpers/esm/defineProperty';
import { Vector3 } from 'three';
import Node from '../core/Node.js';
import UniformNode from '../core/UniformNode.js';
import { NodeUpdateType } from '../core/constants.js';

class Object3DNode extends Node {
  constructor(scope = Object3DNode.VIEW_MATRIX, object3d = null) {
    super();
    this.scope = scope;
    this.object3d = object3d;
    this.updateType = NodeUpdateType.Object;
    this._uniformNode = new UniformNode(null);
  }

  getNodeType() {
    const scope = this.scope;

    if (scope === Object3DNode.WORLD_MATRIX || scope === Object3DNode.VIEW_MATRIX) {
      return 'mat4';
    } else if (scope === Object3DNode.NORMAL_MATRIX) {
      return 'mat3';
    } else if (scope === Object3DNode.POSITION || scope === Object3DNode.VIEW_POSITION) {
      return 'vec3';
    }
  }

  update(frame) {
    const object = this.object3d !== null ? this.object3d : frame.object;
    const uniformNode = this._uniformNode;
    const camera = frame.camera;
    const scope = this.scope;

    if (scope === Object3DNode.VIEW_MATRIX) {
      uniformNode.value = object.modelViewMatrix;
    } else if (scope === Object3DNode.NORMAL_MATRIX) {
      uniformNode.value = object.normalMatrix;
    } else if (scope === Object3DNode.WORLD_MATRIX) {
      uniformNode.value = object.matrixWorld;
    } else if (scope === Object3DNode.POSITION) {
      uniformNode.value.setFromMatrixPosition(object.matrixWorld);
    } else if (scope === Object3DNode.VIEW_POSITION) {
      uniformNode.value.setFromMatrixPosition(object.matrixWorld);
      uniformNode.value.applyMatrix4(camera.matrixWorldInverse);
    }
  }

  generate(builder) {
    const scope = this.scope;

    if (scope === Object3DNode.WORLD_MATRIX || scope === Object3DNode.VIEW_MATRIX) {
      this._uniformNode.nodeType = 'mat4';
    } else if (scope === Object3DNode.NORMAL_MATRIX) {
      this._uniformNode.nodeType = 'mat3';
    } else if (scope === Object3DNode.POSITION || scope === Object3DNode.VIEW_POSITION) {
      this._uniformNode.nodeType = 'vec3';
      this._uniformNode.value = new Vector3();
    }

    return this._uniformNode.build(builder);
  }

  serialize(data) {
    super.serialize(data);
    data.scope = this.scope;
  }

  deserialize(data) {
    super.deserialize(data);
    this.scope = data.scope;
  }

}

_defineProperty(Object3DNode, "VIEW_MATRIX", 'viewMatrix');

_defineProperty(Object3DNode, "NORMAL_MATRIX", 'normalMatrix');

_defineProperty(Object3DNode, "WORLD_MATRIX", 'worldMatrix');

_defineProperty(Object3DNode, "POSITION", 'position');

_defineProperty(Object3DNode, "VIEW_POSITION", 'viewPosition');

export default Object3DNode;
