const NodeShaderStage = {
  Vertex: 'vertex',
  Fragment: 'fragment'
};
const NodeUpdateType = {
  None: 'none',
  Frame: 'frame',
  Object: 'object'
};

export { NodeShaderStage, NodeUpdateType };
