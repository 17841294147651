import _defineProperty from '@babel/runtime/helpers/esm/defineProperty';
import Node from '../core/Node.js';
import AttributeNode from '../core/AttributeNode.js';
import VaryNode from '../core/VaryNode.js';
import ModelNode from './ModelNode.js';
import CameraNode from './CameraNode.js';
import OperatorNode from '../math/OperatorNode.js';
import MathNode from '../math/MathNode.js';

class NormalNode extends Node {
  constructor(scope = NormalNode.LOCAL) {
    super('vec3');
    this.scope = scope;
  }

  getHash() {
    return `normal-${this.scope}`;
  }

  generate(builder) {
    const scope = this.scope;
    let outputNode = null;

    if (scope === NormalNode.GEOMETRY) {
      outputNode = new AttributeNode('normal', 'vec3');
    } else if (scope === NormalNode.LOCAL) {
      outputNode = new VaryNode(new NormalNode(NormalNode.GEOMETRY));
    } else if (scope === NormalNode.VIEW) {
      const vertexNormalNode = new OperatorNode('*', new ModelNode(ModelNode.NORMAL_MATRIX), new NormalNode(NormalNode.LOCAL));
      outputNode = new MathNode(MathNode.NORMALIZE, new VaryNode(vertexNormalNode));
    } else if (scope === NormalNode.WORLD) {
      // To use INVERSE_TRANSFORM_DIRECTION only inverse the param order like this: MathNode( ..., Vector, Matrix );
      const vertexNormalNode = new MathNode(MathNode.TRANSFORM_DIRECTION, new NormalNode(NormalNode.VIEW), new CameraNode(CameraNode.VIEW_MATRIX));
      outputNode = new MathNode(MathNode.NORMALIZE, new VaryNode(vertexNormalNode));
    }

    return outputNode.build(builder);
  }

  serialize(data) {
    super.serialize(data);
    data.scope = this.scope;
  }

  deserialize(data) {
    super.deserialize(data);
    this.scope = data.scope;
  }

}

_defineProperty(NormalNode, "GEOMETRY", 'geometry');

_defineProperty(NormalNode, "LOCAL", 'local');

_defineProperty(NormalNode, "WORLD", 'world');

_defineProperty(NormalNode, "VIEW", 'view');

export default NormalNode;
