import AttributeNode from '../core/AttributeNode.js';

class UVNode extends AttributeNode {
  constructor(index = 0) {
    super(null, 'vec2');
    this.index = index;
  }

  getAttributeName() {
    const index = this.index;
    return 'uv' + (index > 0 ? index + 1 : '');
  }

  serialize(data) {
    super.serialize(data);
    data.index = this.index;
  }

  deserialize(data) {
    super.deserialize(data);
    this.index = data.index;
  }

}

UVNode.prototype.isUVNode = true;

export default UVNode;
