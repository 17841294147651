class NodeVary {
  constructor(name, type) {
    this.name = name;
    this.type = type;
  }

}

NodeVary.prototype.isNodeVary = true;

export default NodeVary;
