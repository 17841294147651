import ArrayUniformNode from './core/ArrayUniformNode.js';
export { default as ArrayUniformNode } from './core/ArrayUniformNode.js';
import AttributeNode from './core/AttributeNode.js';
export { default as AttributeNode } from './core/AttributeNode.js';
import BypassNode from './core/BypassNode.js';
export { default as BypassNode } from './core/BypassNode.js';
import CodeNode from './core/CodeNode.js';
export { default as CodeNode } from './core/CodeNode.js';
import ConstNode from './core/ConstNode.js';
export { default as ConstNode } from './core/ConstNode.js';
import ContextNode from './core/ContextNode.js';
export { default as ContextNode } from './core/ContextNode.js';
import ExpressionNode from './core/ExpressionNode.js';
export { default as ExpressionNode } from './core/ExpressionNode.js';
import FunctionCallNode from './core/FunctionCallNode.js';
export { default as FunctionCallNode } from './core/FunctionCallNode.js';
import FunctionNode from './core/FunctionNode.js';
export { default as FunctionNode } from './core/FunctionNode.js';
import Node from './core/Node.js';
export { default as Node } from './core/Node.js';
import NodeAttribute from './core/NodeAttribute.js';
export { default as NodeAttribute } from './core/NodeAttribute.js';
import NodeBuilder from './core/NodeBuilder.js';
export { default as NodeBuilder } from './core/NodeBuilder.js';
import NodeCode from './core/NodeCode.js';
export { default as NodeCode } from './core/NodeCode.js';
import NodeFrame from './core/NodeFrame.js';
export { default as NodeFrame } from './core/NodeFrame.js';
import NodeFunctionInput from './core/NodeFunctionInput.js';
export { default as NodeFunctionInput } from './core/NodeFunctionInput.js';
import NodeKeywords from './core/NodeKeywords.js';
export { default as NodeKeywords } from './core/NodeKeywords.js';
import NodeUniform from './core/NodeUniform.js';
export { default as NodeUniform } from './core/NodeUniform.js';
import NodeVar from './core/NodeVar.js';
export { default as NodeVar } from './core/NodeVar.js';
import NodeVary from './core/NodeVary.js';
export { default as NodeVary } from './core/NodeVary.js';
import PropertyNode from './core/PropertyNode.js';
export { default as PropertyNode } from './core/PropertyNode.js';
import TempNode from './core/TempNode.js';
export { default as TempNode } from './core/TempNode.js';
import UniformNode from './core/UniformNode.js';
export { default as UniformNode } from './core/UniformNode.js';
import VarNode from './core/VarNode.js';
export { default as VarNode } from './core/VarNode.js';
import VaryNode from './core/VaryNode.js';
export { default as VaryNode } from './core/VaryNode.js';
import BufferNode from './accessors/BufferNode.js';
export { default as BufferNode } from './accessors/BufferNode.js';
import CameraNode from './accessors/CameraNode.js';
export { default as CameraNode } from './accessors/CameraNode.js';
import CubeTextureNode from './accessors/CubeTextureNode.js';
export { default as CubeTextureNode } from './accessors/CubeTextureNode.js';
import MaterialNode from './accessors/MaterialNode.js';
export { default as MaterialNode } from './accessors/MaterialNode.js';
import MaterialReferenceNode from './accessors/MaterialReferenceNode.js';
export { default as MaterialReferenceNode } from './accessors/MaterialReferenceNode.js';
import ModelNode from './accessors/ModelNode.js';
export { default as ModelNode } from './accessors/ModelNode.js';
import ModelViewProjectionNode from './accessors/ModelViewProjectionNode.js';
export { default as ModelViewProjectionNode } from './accessors/ModelViewProjectionNode.js';
import NormalNode from './accessors/NormalNode.js';
export { default as NormalNode } from './accessors/NormalNode.js';
import Object3DNode from './accessors/Object3DNode.js';
export { default as Object3DNode } from './accessors/Object3DNode.js';
import PointUVNode from './accessors/PointUVNode.js';
export { default as PointUVNode } from './accessors/PointUVNode.js';
import PositionNode from './accessors/PositionNode.js';
export { default as PositionNode } from './accessors/PositionNode.js';
import ReferenceNode from './accessors/ReferenceNode.js';
export { default as ReferenceNode } from './accessors/ReferenceNode.js';
import ReflectNode from './accessors/ReflectNode.js';
export { default as ReflectNode } from './accessors/ReflectNode.js';
import SkinningNode from './accessors/SkinningNode.js';
export { default as SkinningNode } from './accessors/SkinningNode.js';
import TextureNode from './accessors/TextureNode.js';
export { default as TextureNode } from './accessors/TextureNode.js';
import UVNode from './accessors/UVNode.js';
export { default as UVNode } from './accessors/UVNode.js';
import ColorSpaceNode from './display/ColorSpaceNode.js';
export { default as ColorSpaceNode } from './display/ColorSpaceNode.js';
import NormalMapNode from './display/NormalMapNode.js';
export { default as NormalMapNode } from './display/NormalMapNode.js';
import MathNode from './math/MathNode.js';
export { default as MathNode } from './math/MathNode.js';
import OperatorNode from './math/OperatorNode.js';
export { default as OperatorNode } from './math/OperatorNode.js';
import CondNode from './math/CondNode.js';
export { default as CondNode } from './math/CondNode.js';
import LightContextNode from './lights/LightContextNode.js';
export { default as LightContextNode } from './lights/LightContextNode.js';
import LightNode from './lights/LightNode.js';
export { default as LightNode } from './lights/LightNode.js';
import LightsNode from './lights/LightsNode.js';
export { default as LightsNode } from './lights/LightsNode.js';
import ArrayElementNode from './utils/ArrayElementNode.js';
export { default as ArrayElementNode } from './utils/ArrayElementNode.js';
import ConvertNode from './utils/ConvertNode.js';
export { default as ConvertNode } from './utils/ConvertNode.js';
import JoinNode from './utils/JoinNode.js';
export { default as JoinNode } from './utils/JoinNode.js';
import SplitNode from './utils/SplitNode.js';
export { default as SplitNode } from './utils/SplitNode.js';
import SpriteSheetUVNode from './utils/SpriteSheetUVNode.js';
export { default as SpriteSheetUVNode } from './utils/SpriteSheetUVNode.js';
import MatcapUVNode from './utils/MatcapUVNode.js';
export { default as MatcapUVNode } from './utils/MatcapUVNode.js';
import OscNode from './utils/OscNode.js';
export { default as OscNode } from './utils/OscNode.js';
import TimerNode from './utils/TimerNode.js';
export { default as TimerNode } from './utils/TimerNode.js';
import NodeLoader from './loaders/NodeLoader.js';
export { default as NodeLoader } from './loaders/NodeLoader.js';
import NodeObjectLoader from './loaders/NodeObjectLoader.js';
export { default as NodeObjectLoader } from './loaders/NodeObjectLoader.js';
import NodeMaterialLoader from './loaders/NodeMaterialLoader.js';
export { default as NodeMaterialLoader } from './loaders/NodeMaterialLoader.js';
import CheckerNode from './procedural/CheckerNode.js';
export { default as CheckerNode } from './procedural/CheckerNode.js';
import FogNode from './fog/FogNode.js';
export { default as FogNode } from './fog/FogNode.js';
import FogRangeNode from './fog/FogRangeNode.js';
export { default as FogRangeNode } from './fog/FogRangeNode.js';

// core
const nodeLib = {
  // core
  ArrayUniformNode,
  AttributeNode,
  BypassNode,
  CodeNode,
  ContextNode,
  ConstNode,
  ExpressionNode,
  FunctionCallNode,
  FunctionNode,
  Node,
  NodeAttribute,
  NodeBuilder,
  NodeCode,
  NodeFrame,
  NodeFunctionInput,
  NodeKeywords,
  NodeUniform,
  NodeVar,
  NodeVary,
  PropertyNode,
  TempNode,
  UniformNode,
  VarNode,
  VaryNode,
  // accessors
  BufferNode,
  CameraNode,
  CubeTextureNode,
  MaterialNode,
  MaterialReferenceNode,
  ModelNode,
  ModelViewProjectionNode,
  NormalNode,
  Object3DNode,
  PointUVNode,
  PositionNode,
  ReferenceNode,
  ReflectNode,
  SkinningNode,
  TextureNode,
  UVNode,
  // display
  ColorSpaceNode,
  NormalMapNode,
  // math
  MathNode,
  OperatorNode,
  CondNode,
  // lights
  LightContextNode,
  LightNode,
  LightsNode,
  // utils
  ArrayElementNode,
  ConvertNode,
  JoinNode,
  SplitNode,
  SpriteSheetUVNode,
  MatcapUVNode,
  OscNode,
  TimerNode,
  // procedural
  CheckerNode,
  // fog
  FogNode,
  FogRangeNode,
  // loaders
  NodeLoader,
  NodeObjectLoader,
  NodeMaterialLoader
};
const fromType = type => {
  return new nodeLib[type]();
};

export { fromType };
