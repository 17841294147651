/**
 * Unpack RGBA depth shader
 * - show RGBA encoded depth as monochrome color
 */
const UnpackDepthRGBAShader = {
  uniforms: {
    tDiffuse: {
      value: null
    },
    opacity: {
      value: 1.0
    }
  },
  vertexShader: ['varying vec2 vUv;', 'void main() {', '	vUv = uv;', '	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );', '}'].join('\n'),
  fragmentShader: ['uniform float opacity;', 'uniform sampler2D tDiffuse;', 'varying vec2 vUv;', '#include <packing>', 'void main() {', '	float depth = 1.0 - unpackRGBAToDepth( texture2D( tDiffuse, vUv ) );', '	gl_FragColor = vec4( vec3( depth ), opacity );', '}'].join('\n')
};

export { UnpackDepthRGBAShader };
